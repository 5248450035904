import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { SentenceCasePipe } from '../pipes/sentence.pipe';

interface IPosition {
  top: number;
  left: number;
}

@Directive({
  selector: '[ag1Tooltip]',
})
export class Ag1TooltipDirective implements OnDestroy {
  @Input('ag1Tooltip') tooltipText = '';
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() tooltipVariant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'success' = 'primary';
  @Input() tooltipClassOverrides = '';
  @Input()
  formatWithSentenceCase: boolean = true;

  private static tooltipElement: HTMLElement | null = null;
  private static activeInstance: Ag1TooltipDirective | null = null;

  private showTimeout: ReturnType<typeof setTimeout> | null = null;
  private hideTimeout: ReturnType<typeof setTimeout> | null = null;

  private readonly className = 'ag1-tooltip';
  private readonly showDelay = 200;
  private readonly hideDelay = 0;
  private readonly fadeInDelay = 20;
  private readonly fadeOutDuration = 200;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private sentenceCasePipe: SentenceCasePipe
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.ngZone.runOutsideAngular(() => {
      this.clearTimeouts();
      this.showTimeout = setTimeout(() => this.show(), this.showDelay);
    });
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.clearTimeouts();
    this.hideTimeout = setTimeout(() => this.hide(), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.hide();
    this.clearTimeouts();
  }

  private show(): void {
    this.hideActiveTooltip();
    this.createOrUpdateTooltip();
    this.setPosition();
    this.makeVisible();
    Ag1TooltipDirective.activeInstance = this;
  }

  private hide(): void {
    if (Ag1TooltipDirective.tooltipElement) {
      this.fadeOutTooltip();
    }
    this.clearActiveInstance();
  }

  private createOrUpdateTooltip(): void {
    if (!this.tooltipText?.length) {
      return;
    }

    if (!Ag1TooltipDirective.tooltipElement) {
      this.createTooltipElement();
    } else {
      this.updateTooltipContent();
    }
  }

  private createTooltipElement(): void {
    Ag1TooltipDirective.tooltipElement = this.renderer.createElement('div');
    this.applyTooltipClasses();
    this.updateTooltipContent();
    this.renderer.appendChild(
      document.body,
      Ag1TooltipDirective.tooltipElement
    );
  }

  private applyTooltipClasses(): void {
    if (!Ag1TooltipDirective.tooltipElement) {
      return;
    }

    this.renderer.addClass(Ag1TooltipDirective.tooltipElement, this.className);
    this.renderer.addClass(
      Ag1TooltipDirective.tooltipElement,
      `${this.className}--${this.tooltipVariant}`
    );

    if (this.tooltipClassOverrides) {
      this.tooltipClassOverrides.split(' ').forEach((className: string) => {
        this.renderer.addClass(
          Ag1TooltipDirective.tooltipElement,
          className.trim()
        );
      });
    }
  }

  private updateTooltipContent(): void {
    if (Ag1TooltipDirective.tooltipElement) {
      if (this.formatWithSentenceCase) {
        Ag1TooltipDirective.tooltipElement.textContent =
          this.sentenceCasePipe.transform(this.tooltipText);
      } else {
        Ag1TooltipDirective.tooltipElement.innerHTML = this.tooltipText;
      }
    }
  }

  private setPosition(): void {
    if (!Ag1TooltipDirective.tooltipElement) {
      return;
    }

    const position: IPosition = this.calculatePosition();
    this.renderer.setStyle(
      Ag1TooltipDirective.tooltipElement,
      'top',
      `${position.top}px`
    );
    this.renderer.setStyle(
      Ag1TooltipDirective.tooltipElement,
      'left',
      `${position.left}px`
    );
  }

  private calculatePosition(): IPosition {
    const hostPos: DOMRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos: DOMRect =
      Ag1TooltipDirective.tooltipElement!.getBoundingClientRect();
    const scrollPos: IPosition = this.getScrollPosition();

    let position: IPosition = { top: 0, left: 0 };
    const tooltipOffset = 8;

    switch (this.tooltipPosition) {
      case 'right':
        position.top =
          hostPos.top +
          (hostPos.height - tooltipPos.height) / 2 +
          scrollPos.top;
        position.left = hostPos.right + tooltipOffset + scrollPos.left;
        break;
      case 'left':
        position.top =
          hostPos.top +
          (hostPos.height - tooltipPos.height) / 2 +
          scrollPos.top;
        position.left =
          hostPos.left - tooltipPos.width - tooltipOffset + scrollPos.left;
        break;
      case 'bottom':
        position.top = hostPos.bottom + tooltipOffset + scrollPos.top;
        position.left =
          hostPos.left +
          (hostPos.width - tooltipPos.width) / 2 +
          scrollPos.left;
        break;
      default:
        // top
        position.top =
          hostPos.top - tooltipPos.height - tooltipOffset + scrollPos.top;
        position.left =
          hostPos.left +
          (hostPos.width - tooltipPos.width) / 2 +
          scrollPos.left;
    }

    return this.adjustPositionToViewport(position, tooltipPos);
  }

  private getScrollPosition(): IPosition {
    return {
      top:
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0,
      left:
        window.scrollX ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft ||
        0,
    };
  }

  private adjustPositionToViewport(
    position: IPosition,
    tooltipPos: DOMRect
  ): IPosition {
    const scrollPos: IPosition = this.getScrollPosition();
    const viewportWidth = window.innerWidth + scrollPos.left;
    const viewportHeight = window.innerHeight + scrollPos.top;

    // if tooltip leaks out on the left of the viewport
    if (position.left < scrollPos.left) {
      position.left = scrollPos.left;
    }

    // if tooltip leaks out on the right of the viewport
    if (position.left + tooltipPos.width > viewportWidth) {
      position.left = viewportWidth - tooltipPos.width;
    }

    // if tooltip leaks out on the top of the viewport
    if (position.top < scrollPos.top) {
      position.top = scrollPos.top;
    }

    // if tooltip leaks out on the bottom of the viewport
    if (position.top + tooltipPos.height > viewportHeight) {
      position.top = viewportHeight - tooltipPos.height;
    }

    return position;
  }

  private makeVisible(): void {
    if (!Ag1TooltipDirective.tooltipElement) {
      return;
    }

    this.renderer.setStyle(
      Ag1TooltipDirective.tooltipElement,
      'display',
      'block'
    );

    setTimeout(() => {
      if (Ag1TooltipDirective.tooltipElement) {
        this.renderer.setStyle(
          Ag1TooltipDirective.tooltipElement,
          'opacity',
          '1'
        );
      }
    }, this.fadeInDelay);
  }

  private fadeOutTooltip(): void {
    this.renderer.setStyle(Ag1TooltipDirective.tooltipElement, 'opacity', '0');
    setTimeout(() => {
      if (Ag1TooltipDirective.tooltipElement) {
        this.renderer.removeChild(
          document.body,
          Ag1TooltipDirective.tooltipElement
        );

        Ag1TooltipDirective.tooltipElement = null;
      }
    }, this.fadeOutDuration);
  }

  private hideActiveTooltip(): void {
    if (
      Ag1TooltipDirective.activeInstance &&
      Ag1TooltipDirective.activeInstance !== this
    ) {
      Ag1TooltipDirective.activeInstance.hide();
    }
  }

  private clearActiveInstance(): void {
    if (Ag1TooltipDirective.activeInstance === this) {
      Ag1TooltipDirective.activeInstance = null;
    }
  }

  private clearTimeouts(): void {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
      this.showTimeout = null;
    }
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  }
}
