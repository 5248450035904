export const environment = {
  production: true,
  staging: false,
  development: false,
  apiBaseUrl: 'https://api.agrigateone.com',
  webSocketUrl: 'wss://api.agrigateone.com/cable',
  authentication: {
    auth0Domain: 'agrigate.eu.auth0.com',
    clientID: '9OWk3exU6HF3IZOB4lO0LYrCEX3Do2hB',
    redirectURI: 'https://app.agrigateone.com/login/callback',
    audience: 'https://api.agrigate.com',
    webappUrl: 'https://app.agrigateone.com',
    logoutUrl: 'https://app.agrigateone.com/login',
  },
  pusher: {
    key: '63f9e77a43c7876e9dca',
    options: {
      cluster: 'eu',
      encrypted: true,
    },
  },
  sentry: {
    environment: 'production',
    dsn: 'https://f992693bf9654428972bd8968d2a13fa@sentry.io/1365705',
    logToSentry: true,
  },
  freshdesk: {
    domain: 'agrigate',
  },
  gcp: {
    maps: {
      apiKey: 'AIzaSyBiJXMwNwB83uBb5yyaN-P6CamuCA-6ZOE',
    },
  },
  angulartics2Enabled: true,
};
