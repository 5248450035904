import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  selectedList: string;
  selectedLink: string;
  sidebarExpanded = true;
  menuOpen = false;

  constructor() {}

  get expandedSidebar(): boolean {
    // test localStorage on false so sidebar defaults open if key is not set
    this.sidebarExpanded = localStorage.getItem('expandedSidebar') !== 'false';
    return this.sidebarExpanded;
  }

  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
    localStorage.setItem('expandedSidebar', this.sidebarExpanded.toString());
    // Trigger resize event to force chart and table redraws
    // https://github.com/swimlane/ngx-datatable/issues/923#issuecomment-377803539
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  public expandList(list: string): void {
    if (this.expandedList(list)) {
      this.selectedList = null;
      return;
    }

    this.selectedList = list;
  }

  public expandedList(list: string): boolean {
    return this.selectedList === list;
  }

  public activateLink(link: string): void {
    this.selectedLink = link;
  }

  public activeLink(link: string): boolean {
    return this.selectedLink === link;
  }

  public collapseLists(): void {
    this.selectedList = null;
  }

  public toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  public closeMenu(): void {
    this.menuOpen = false;
  }
  public openMenu(): void {
    this.menuOpen = true;
  }
}
