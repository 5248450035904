<div class="flex gap-4 flex-col">
  <div
    class="flex gap-2 items-center whitespace-nowrap flex-wrap justify-between"
  >
    <ag1-title label="404 Error"> </ag1-title>
  </div>
  <div
    class="
      grid
      place-items-center
      gap-2
      h-[calc(100vh-13rem)]
      @container
      overflow-hidden
    "
  >
    <ng-container *ngTemplateOutlet="fourOhFourDisplay"></ng-container>
  </div>
</div>

<ng-template #fourOhFourDisplay>
  <div
    class="
      grid grid-cols-1
      @lg:grid-cols-2
      gap-1
      items-center
      h-full
      overflow-auto
      max-w-4xl
    "
  >
    <div
      class="
        flex flex-col
        items-center
        text-center
        @lg:text-start
        @lg:items-start
        gap-6
        p-8
        max-w-lg
      "
    >
      <img
        src="/assets/images/error_images/404-orange-bg.png"
        alt="404 Image"
        class="w-52 @lg:hidden"
      />

      <h1 class="text-5xl font-extrabold text-steel-blue-600">
        Page Not Found
      </h1>

      <p class="text-xl text-gray-600 mt-4 text-left">
        Whoops! It seems the page
        <ng-container *ngIf="previousUrl">
          <b class="font-semibold">"{{ previousUrl }}"</b>
        </ng-container>
        <ng-container *ngIf="!previousUrl"> you're searching for </ng-container>
        is not available or missing. Feel free to contact us if you feel
        something is incorrect.
      </p>
      <span class="font-semibold">Error code: 404</span>
      <div class="flex flex-row items-center gap-3 mt-6">
        <ag1-button
          class="text-white font-semibold"
          variant="primary"
          (clickEvent)="goBack()"
          >Go Back</ag1-button
        >
        <ag1-button
          class="text-white font-semibold"
          variant="secondary"
          (clickEvent)="goHome()"
          >Go Home</ag1-button
        >
      </div>
    </div>

    <div class="relative hidden @lg:flex justify-center items-center">
      <div
        class="
          absolute
          top-0
          left-0
          z-0
          transform
          translate-x-10 translate-y-5
          scale-150
        "
      >
        <p
          class="
            text-4xl
            @md:text-7xl
            lg:text-9xl
            font-semibold
            text-orange-500
            opacity-40
          "
        >
          4
        </p>
      </div>

      <div
        class="
          absolute
          bottom-0
          right-0
          z-0
          transform
          -translate-x-10
          translate-y-5
          scale-150
        "
      >
        <p
          class="
            text-4xl
            @md:text-7xl
            lg:text-9xl
            font-semibold
            text-orange-500
            opacity-40
          "
        >
          4
        </p>
      </div>

      <div class="z-10">
        <img
          src="/assets/images/error_images/404-orange-bg.png"
          alt="404 Image"
          width="450"
          class="w-96"
        />
      </div>
    </div>
  </div>
</ng-template>
