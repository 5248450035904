<h1
  class="
    !text-slate-800 !text-xl
    !font-semibold
    !select-none
    !whitespace-nowrap
    !capitalize
  "
>
  {{ label }}
</h1>
