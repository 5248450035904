<div
  class="
    {{ classOverrides }}
    rounded-full
    animate-spin
    w-10
    h-10
    select-none
    border-t-french-blue-500 border-8
  "
></div>
