import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgrigateoneIconComponent } from '../shared/icons/components/agrigateone-icon/agrigateone-icon.component';
import { LandingIconComponent } from '../shared/icons/components/landing-icon/landing-icon.component';
import { LandingComponent } from './landing.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule, CommonModule, FontAwesomeModule],
  exports: [LandingComponent, AgrigateoneIconComponent, LandingIconComponent],
  declarations: [
    LandingComponent,
    AgrigateoneIconComponent,
    LandingIconComponent,
  ],
})
export class LandingModule {}
