import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any) {
    if (environment.sentry.logToSentry) {
      Sentry.captureException(err);
    }

    console.error(err);
  }
}
