import { Injectable } from '@angular/core';
import { UserGQL } from '@app/generated/graphql';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private userGql: UserGQL) {}

  uiVersion(): Observable<number> {
    return of(2);
    // saved for future use, e.g. version 3
    // return this.userGql.watch().valueChanges.pipe(
    //   map(res => ({useVersion2: res.data.myUser.useV2} as UseVersion2Result))
    // );
  }
}
