<span class="bg-white flex items-center rounded w-fit">
  <span
    class="ag1-badge"
    [ngClass]="colorClassOverride ? colorClassOverride : 'ag1-badge-' + color"
  >
    <div class="flex gap-2 items-center">
      <ng-content></ng-content>
      <span> {{ label }}</span>
    </div>
  </span>
</span>
