/**
 * This is a module created with the requirement of doing file Uploads in Angular via a GraphQL Mutation
 * It is a combination of the following Http Link implementations for GraphQL
 * apollo-angular | https://github.com/apollographql/apollo-angular
 * apollo-upload-client | https://github.com/jaydenseric/apollo-upload-client
 *
 * For more details. Please refer to the GraphQL multipart request specification:
 * https://github.com/jaydenseric/graphql-multipart-request-spec
 */
import {ApolloLink, Observable as LinkObservable, Operation, RequestHandler, FetchResult} from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { print } from 'graphql/language/printer';

import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEventType,
  HttpUploadProgressEvent,
} from '@angular/common/http';



import { Options, Context, prioritize } from 'apollo-angular-link-http-common';

import { extractFiles } from 'extract-files';
import { Subscription } from 'rxjs';

export class HttpLinkHandler extends ApolloLink {
  public requester: RequestHandler;

  constructor(private httpClient: HttpClient, private options: Options) {
    super();

    this.requester = (operation: Operation) =>
      new LinkObservable((observer: any) => {
        const context: Context = operation.getContext();

        // decides which value to pick, Context, Options or to just use the default
        const pick = <K extends keyof Context | keyof Options>(
          key: K,
          init?: Context[K] | Options[K]
        ): Context[K] | Options[K] => {
          return prioritize(context[key], this.options[key], init);
        };

        const includeQuery = pick('includeQuery', true);
        const includeExtensions = pick('includeExtensions', false);
        const method = pick('method', 'POST');
        const url = pick('uri', 'graphql');
        const withCredentials = pick('withCredentials');
        let reportProgress = false;

        const requestOperation: any = {
          operationName: operation.operationName,
          variables: operation.variables,
        };

        if (includeExtensions) {
          requestOperation.extensions = operation.extensions;
        }

        if (includeQuery) {
          requestOperation.query = print(operation.query);
        }

        // File upload, we need FormData to do a multipart/form-data POST
        let body: any;
        const { _, files } = extractFiles(operation);

        if (files.size) {
          const form = new FormData();

          form.append('operations', JSON.stringify(requestOperation));

          const map = {};
          let i = 0;
          files.forEach((paths) => {
            map[++i] = paths;
          });
          form.append('map', JSON.stringify(map));

          i = 0;
          files.forEach((__, file) => {
            form.append((++i).toString(), file, file.name);
          });

          body = form;
          reportProgress = true;
        } else {
          // No files, keep the body as JSON
          body = requestOperation;
        }

        const req = new HttpRequest(method, url as string, body, {
          withCredentials,
          reportProgress,
          headers: this.options.headers,
        });

        const ctx = <any>context;

        const sub = this.httpClient.request(req).subscribe({
          next: (response) => {
            operation.setContext({ response });
            if (response instanceof HttpResponse) {
              observer.next(response.body);
            } else if (response.type === HttpEventType.UploadProgress) {
              if (ctx.fetchOptions && ctx.fetchOptions.onUploadProgress) {
                ctx.fetchOptions.onUploadProgress(
                  response as HttpUploadProgressEvent
                );
              }
            }
          },
          error: (err) => observer.error(err),
          complete: () => observer.complete(),
        });

        let cancelRequestSub: Subscription;
        if (ctx.fetchOptions && ctx.fetchOptions.cancelRequest) {
          cancelRequestSub = ctx.fetchOptions.cancelRequest.subscribe(() => {
            sub.unsubscribe();
          });
        }

        return () => {
          if (!sub.closed) {
            sub.unsubscribe();
          }

          if (cancelRequestSub && !cancelRequestSub.closed) {
            cancelRequestSub.unsubscribe();
          }
        };
      });
  }

  public request(op: Operation): LinkObservable<FetchResult> | null {
    return this.requester(op, null);
  }
}

@Injectable()
export class HttpLink {
  constructor(private httpClient: HttpClient) {}

  public create(options: Options): HttpLinkHandler {
    return new HttpLinkHandler(this.httpClient, options);
  }
}
