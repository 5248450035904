import { Injectable } from '@angular/core';
import { PivotReport } from '@app/core/components/pivot-table/toolbar/toolbar.component';
import {
  CreateUiConfigGQL,
  DeleteUiConfigGQL,
  UiConfigsGQL,
  UpdateUiConfigGQL,
} from '@app/generated/graphql';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IToolbarService {
  load(configType: string): Observable<PivotReport[]>;
  create(report: PivotReport): Observable<PivotReport>;
  update(report: PivotReport): Observable<PivotReport>;
  delete(report: PivotReport): Observable<number>;
}

@Injectable()
export class ToolbarService implements IToolbarService {
  constructor(
    private uiConfigs: UiConfigsGQL,
    private createUiConfig: CreateUiConfigGQL,
    private updateUiConfig: UpdateUiConfigGQL,
    private deleteUiConfig: DeleteUiConfigGQL
  ) {}

  load(configType: string): Observable<PivotReport[]> {
    const subject = new Subject<PivotReport[]>();

    subject.subscribe({
      error: (e) => console.error(e),
    });

    this.uiConfigs
      .fetch({
        configType: configType,
      })
      .pipe(
        map((res) => {
          const reports = res.data.uiConfigs
            .map((cfg) => {
              const report: PivotReport = {
                id: cfg.id,
                configType: cfg.configType,
                readonly: cfg.readonly,
                cols: [...cfg.data.cols.map((r) => ({ ...r }))],
                rows: [...cfg.data.rows.map((r) => ({ ...r }))],
                values: [...cfg.data.values.map((r) => ({ ...r }))],
                renderer: cfg.data.renderer || 'table',
                dirty: false,
                visibility: cfg.visibility,
                name: cfg.data.name,
              };
              return report;
            })
            .sort((a, b) => {
              if (a.name == b.name) return 0;

              return a.name < b.name ? -1 : 1;
            });

          return reports;
        })
      )
      .subscribe(subject);

    return subject;
  }

  update(report: PivotReport): Observable<PivotReport> {
    const subject = new Subject<PivotReport>();

    subject.subscribe({
      error: (err) => {
        console.error(err);
      },
    });

    this.updateUiConfig
      .mutate({
        attributes: {
          id: report.id,
          configType: report.configType,
          visibility: report.visibility,
          data: this.mapFrontendReportToBackendReport(report),
        },
      })
      .pipe(
        map((res) => {
          const backendReport = res.data.updateUiConfig.config;
          report.readonly = backendReport.readonly;
          report.dirty = false;

          return report;
        })
      )
      .subscribe(subject);

    return subject;
  }

  create(report: PivotReport): Observable<PivotReport> {
    const subject = new Subject<PivotReport>();

    this.createUiConfig
      .mutate({
        attributes: {
          configType: report.configType,
          visibility: report.visibility,
          data: this.mapFrontendReportToBackendReport(report),
        },
      })
      .pipe(
        map((res) => {
          const createdCfg = res.data.createUiConfig.config;

          report.id = createdCfg.id;
          report.readonly = createdCfg.readonly;
          report.dirty = false;

          return report;
        })
      )
      .subscribe(subject);

    subject.subscribe({
      error: (err) => {
        console.error(err);
      },
    });

    return subject;
  }

  delete(report: PivotReport) {
    const subject = new Subject<number>();

    this.deleteUiConfig
      .mutate({ deleteUiConfigId: report.id })
      .pipe(map((res) => res.data.deleteUiConfig.id))
      .subscribe(subject);

    subject.subscribe({
      error: (err) => {
        console.error(err);
      },
    });

    return subject;
  }

  mapFrontendReportToBackendReport(report: PivotReport) {
    return {
      name: report.name,
      rows: report.rows,
      cols: report.cols,
      values: report.values,
      renderer: report.renderer,
      visibility: report.visibility,
    };
  }
}
