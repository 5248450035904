import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IColors =
  | 'red'
  | 'orange'
  | 'amber'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'emerald'
  | 'teal'
  | 'cyan'
  | 'sky'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'purple'
  | 'fuchsia'
  | 'pink'
  | 'rose'
  | 'slate';

@Component({
  selector: 'ag1-badge',
  templateUrl: './ag1-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Ag1BadgeComponent {
  /**
   * @param label What you'd like to display in the badge
   * @example 'Delivered'
   * */
  @Input() label: string;
  /**
   * @param color The color of the badge
   * @example 'green'
   * */
  @Input() color: IColors = 'slate';
  /**
   * @param colorClassOverride If you want to override the default color classes, you can pass in a string here
   * @example 'is-line-item-accepted'
   */
  @Input() colorClassOverride: string;

  constructor() {}
}
