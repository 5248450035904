import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }

    // Handle leading spaces and numbers
    const match = value.match(/^(\s*)(\d*)(\s*)(.*?)(\s*)$/);
    if (!match) return value;

    const [
      ,
      leadingSpaces,
      numbers,
      spaceAfterNumbers,
      content,
      trailingSpaces,
    ] = match;

    // If content is empty or starts with a special character, return original value
    if (!content || /^[!@#$%^&*(),.?":{}|<>]/.test(content)) {
      return value;
    }

    // Transform the string while preserving special characters
    return (
      leadingSpaces +
      numbers +
      spaceAfterNumbers +
      content.charAt(0).toLocaleUpperCase() +
      content.slice(1).toLocaleLowerCase() +
      trailingSpaces
    );
  }
}
