<ul
  class="
    fixed
    bottom-5
    grid grid-cols-1
    gap-5
    place-items-center
    inset-x-5
    z-[99999]
  "
  [ngClass]="[
    expandedSidebar
      ? 'sm:portrait:left-64 md:landscape:left-64'
      : 'sm:portrait:left-16 md:landscape:left-16'
  ]"
>
  <ng-container *ngFor="let toast of toasts; let index = index">
    <div class="rounded-lg bg-white">
      <div
        class="relative rounded-lg border border-l-8 p-3 shadow-md"
        [ngClass]="toastStyle(toast.type)"
      >
        <h1 class="mb-3 mr-8 font-semibold capitalize text-xl">
          {{ toast.type }}
        </h1>
        <div class="mr-8 text-lg">{{ toast.message }}</div>

        <ag1-button
          class="absolute right-1 top-1"
          (clickEvent)="closeToast(index)"
          variant="secondary"
          tooltip="Close"
        >
          <svg-icon src="/assets/icons/general/close.svg"></svg-icon>
        </ag1-button>
      </div>
    </div>
  </ng-container>
</ul>
