import { Component, ChangeDetectionStrategy } from '@angular/core';
import Ag1ButtonBaseComponent from '../ag1-button-base/ag1-button-base.component';

@Component({
  selector: 'ag1-button-small',
  templateUrl: './ag1-button-small.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class Ag1ButtonSmallComponent extends Ag1ButtonBaseComponent {
  // logic implemented inside Ag1ButtonBaseComponent
}
