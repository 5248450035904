import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ag1-loader',
  templateUrl: './ag1-loader.component.html',
})
export default class Ag1LoaderComponent implements OnInit {
  @Input()
  classOverrides: string = '';

  ngOnInit(): void {}
}
