import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class CardService {
  constructor(private datePipe: DatePipe) {}

  async scroll(id, scrolling: ScrollBehavior = 'auto') {
    while (!document.getElementById(`${id}`)) {
      await new Promise((r) => setTimeout(r, 100));
    }
    document
      .getElementById(`${id}`)
      .scrollIntoView({ behavior: scrolling, block: 'center' });
  }

  // icons
  getLocationMapIcons(type): string {
    switch (type) {
      case 'Commercial Cold Store':
        return '/assets/icons/dashboard/map/coldstore-marker.svg';
      case 'Vessel':
        return '/assets/icons/dashboard/map/vessel-marker.svg';
      case 'Sea Port Terminal':
        return '/assets/icons/dashboard/map/port-marker.svg';
      case 'Pack House (On-Farm)':
      case 'Pack House (Off-Farm)':
        return '/assets/icons/dashboard/map/packhouse-marker.svg';
      case 'Rail Depot':
        return '/assets/icons/map/rail-marker.svg';
      case 'Airport Terminal / Forwader':
        return '/assets/icons/dashboard/map/plane-marker.svg';

      default:
        return '/assets/icons/dashboard/map/location-marker.svg';
    }
  }

  getLocationIcons(type): string {
    switch (type) {
      case 'Commercial Cold Store':
        return '/assets/icons/dashboard/cards/coldstore.svg';
      case 'Vessel':
        return '/assets/icons/dashboard/cards/vessel.svg';
      case 'Sea Port Terminal':
        return '/assets/icons/dashboard/cards/port.svg';
      case 'Pack House (On-Farm)':
      case 'Pack House (Off-Farm)':
        return '/assets/icons/dashboard/cards/packhouse.svg';
      case 'Rail Depot':
        return '/assets/icons/dashboard/cards/rail.svg';
      case 'Airport Terminal / Forwader':
        return '/assets/icons/dashboard/cards/plane.svg';

      default:
        return '/assets/icons/dashboard/cards/location.svg';
    }
  }

  getContainerIcons(type): string {
    switch (type) {
      case 'Container':
        return '/assets/icons/dashboard/cards/container.svg';
      case 'Unallocated':
        return '/assets/icons/dashboard/cards/batch.svg';

      default:
        return '/assets/icons/dashboard/cards/batch.svg';
    }
  }

  formatField(field): string {
    if (field !== 0 && !field) {
      // 0 evaluates to false, we want to avoid this
      return '';
    }

    if (typeof field === 'number') {
      // formats the number using the users' locale format
      return field.toLocaleString();
    }

    if (typeof field === 'string') {
      // check if string matches a date type
      const dateRegex = /^\d{4}-\d{2}-\d{2}/;
      if (field.match(dateRegex)) {
        // in the future we might need to incorporate different
        // date formats based on the user's en-locale eg.en_US
        // should have the month first
        return this.datePipe.transform(field, 'dd MMM YYYY, HH:mm ');
      } else if (field.match(/_/g)) {
        // text has underscores
        return field
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ');
      } else {
        // leave batches and other fields unformatted
        return field;
      }
    }
  }
}
