import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: any): any {
    if (value && typeof value === 'string') {
      if (this.isAllUpperCase(value)) {
        value = value.toLowerCase();
      }
      return this.toCapitalizedWords(value);
    }
    return value;
  }

  private toCapitalizedWords(value: string) {
    const words = value.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(this.capitalize).join(' ');
  }

  private capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  private isAllUpperCase(str: string) {
    return str === str.toUpperCase();
  }
}
