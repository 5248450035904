import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { PermissionEnum } from '@app/generated/graphql';

@Directive({
  selector:
    '[agHasPermission],[agHasPermissionAny],[agHasPermissionEvery],[agDoesNotHavePermission]',
})
export class HasPermissionDirective {
  resourceIds: PermissionEnum[];
  isHidden = false;
  negate = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  @Input()
  set agHasPermission(resourceId: PermissionEnum) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.resourceIds = [resourceId];
    this.updateView(false);
  }

  @Input()
  set agHasPermissionAny(resourceIds: PermissionEnum[]) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.resourceIds = resourceIds;
    this.updateView(false);
  }

  @Input()
  set agHasPermissionEvery(resourceIds: PermissionEnum[]) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.resourceIds = resourceIds;
    this.updateView(true);
  }

  @Input()
  set agDoesNotHavePermission(resourceId: PermissionEnum) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.resourceIds = [resourceId];
    this.negate = true;
    this.updateView(false);
  }

  async updateView(needsAll: boolean) {
    let hasPermission = await (needsAll
      ? this.allowedEvery()
      : this.allowedAny());
    if (this.negate) {
      hasPermission = !hasPermission;
    }

    if (hasPermission) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  async allowedAny(): Promise<boolean> {
    for (const resourceId of this.resourceIds) {
      const granted = await this.permissionService.allow(resourceId);
      if (granted) {
        return true;
      }
    }
    return false;
  }

  async allowedEvery(): Promise<boolean> {
    for (const resourceId of this.resourceIds) {
      const granted = await this.permissionService.allow(resourceId);
      if (!granted) {
        return false;
      }
    }
    return true;
  }
}
