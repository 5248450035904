import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private eventBus = {};
  constructor(private location: Location) {}

  public setChannel(channel: string, data: any): void {
    this.initialiseNamespace();
    this.initialiseChannel(channel);

    // clone input in order to avoid mutation further down the line
    const clonedData = structuredClone(data);
    this.eventBus[this.namespace][channel].next(clonedData);
  }

  public getChannel(channel: string): BehaviorSubject<any> {
    this.initialiseNamespace();
    this.initialiseChannel(channel);

    return this.eventBus[this.namespace][channel];
  }

  get namespace(): string {
    // extract component name from route
    // '/account/commercial-invoices' => 'commercial-invoices'
    return this.location
      .path()
      .replace(/^\/account\//, '')
      .replace(/\//g, '-')
      .match(/[\d*|a-z|-]*/)[0];
  }

  initialiseNamespace(): void {
    if (!this.eventBus.hasOwnProperty(this.namespace)) {
      this.eventBus[this.namespace] = {};
    }
  }

  initialiseChannel(channel: string) {
    if (!this.eventBus[this.namespace].hasOwnProperty(channel)) {
      this.eventBus[this.namespace][channel] = new BehaviorSubject({});
    }
  }
}
