import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { from, Observable, of, zip } from 'rxjs';
// eslint-disable-next-line import/no-internal-modules
import { map } from 'rxjs/operators';
import { PermissionEnum, User, UserGQL } from '@app/generated/graphql';
import { PermissionService } from '@app/core/services/permission.service';
import * as Sentry from '@sentry/browser';

@Injectable()
export class PermissionGuard implements CanActivate {
  currentUser: User;

  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowed$ = from(this.permissionService.allow(route.data.resourceId));

    return allowed$.pipe(
      map((allowed) => {
        const permissionCheckPassed = allowed;

        if (permissionCheckPassed) {
          return true;
        } else {
          // if this user doesn't have access to the settings page we
          // probably have a permission misconfiguration on our hands
          if (route.data.resourceId === PermissionEnum.PagesSettings) {
            Sentry.captureMessage(
              `User does not have access to page: ${route.data.resourceId}`
            );
          }
          this.router.navigate(['/account/404']);
          return false;
        }
      })
    );
  }
}
