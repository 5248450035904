import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { NgModule } from '@angular/core';

import { HttpLinkModule } from './http-link.module';
import { HttpLink } from './http-link';


import PossibleTypesResultData from '../generated/introspection-result';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { environment } from '../../environments/environment';
import { inflate } from './inflate.js';
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { sha256 } from 'crypto-hash';
import { CableService } from '../core/services/cable/cable.service';

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const uri = environment.apiBaseUrl + '/graphql';
export function createApollo(httpLink: HttpLink, cableService: CableService) {
  const cable = cableService.createCable();
  const subscriptionLink = new ActionCableLink({ cable });

  const queryOrMutationLink = createPersistedQueryLink({ sha256 }).concat(httpLink.create({ uri }));

  const inflateLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      return inflate(response);
    });
  });

  const requestLink = ApolloLink.split(
    hasSubscriptionOperation,
    subscriptionLink,
    queryOrMutationLink
  );

  return {
    link: ApolloLink.from([
      inflateLink,
      requestLink,
    ]),
    cache: new InMemoryCache({ possibleTypes: PossibleTypesResultData.possibleTypes }),
    connectToDevTools: !environment.production,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
    },
  };
}

@NgModule({
  exports: [HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, CableService],
    },
  ],
})
export class GraphQLModule {}
