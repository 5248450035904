import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear); // dependency for isoWeeksInYear

@Injectable({
  providedIn: 'root',
})
export class IsoWeekService {
  private getIsoWeek(date: dayjs.Dayjs): string {
    const year = date.isoWeekYear();
    const week = date.isoWeek();
    return `${year}-W${week.toString().padStart(2, '0')}`;
  }

  get currentWeek(): string {
    return this.getIsoWeek(dayjs());
  }

  previousWeek(weeksToSubtract: number = 1): string {
    const targetDate = dayjs().subtract(weeksToSubtract, 'week');
    return this.getIsoWeek(targetDate);
  }

  nextWeek(weeksToAdd: number = 1): string {
    const targetDate = dayjs().add(weeksToAdd, 'week');
    return this.getIsoWeek(targetDate);
  }
}
