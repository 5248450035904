import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';

export interface Toast {
  id: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastQueue: Toast[] = [];
  private toastSubject = new BehaviorSubject<Toast[]>([]);
  defaultDuration: number = 4000;
  toastCounter = 0;
  constructor() {}

  showToast(message: string, duration?: number): void {
    this.createToast(message, 'info', duration);
  }

  showWarningToast(message: string, duration?: number): void {
    this.createToast(message, 'warning', duration);
  }

  showErrorToast(message: string, duration?: number): void {
    this.createToast(message, 'error', duration);
  }

  showSuccessToast(message: string, duration?: number): void {
    this.createToast(message, 'success', duration);
  }

  createToast(
    message: string,
    type: Toast['type'] = 'info',
    duration: number = this.defaultDuration
  ): void {
    const toastId = `T_${this.toastCounter++}`;
    this.toastQueue.push({ id: toastId, message, type: type });

    // clone input in order to avoid mutation further down the line
    const clonedQueue = structuredClone(this.toastQueue);
    this.toastSubject.next(clonedQueue);
    timer(duration).subscribe(() => this.hideToast(toastId));
  }

  hideToast(toastId: string): void {
    const index = this.toastQueue.findIndex((toast) => toast.id === toastId);
    if (index !== -1) {
      this.toastQueue.splice(index, 1);

      // clone input in order to avoid mutation further down the line
      const clonedQueue = structuredClone(this.toastQueue);
      this.toastSubject.next(clonedQueue);
    }
  }

  getToast(): Observable<Toast[]> {
    return this.toastSubject.asObservable();
  }

  closeToast(index: number): void {
    if (index >= 0 && index < this.toastQueue.length) {
      this.toastQueue.splice(index, 1);

      // clone input in order to avoid mutation further down the line
      const clonedQueue = structuredClone(this.toastQueue);
      this.toastSubject.next(clonedQueue);
    }
  }
}
