import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as ActionCable from '@rails/actioncable';

@Injectable({
  providedIn: 'root',
})
export class CableService {
  consumer: any;

  createCable() {
    if (!this.consumer) {
      this.consumer = ActionCable.createConsumer(
        environment.webSocketUrl +
          '?token=' +
          localStorage.getItem('access_token')
      );
    }
    return this.consumer;
  }

  toggleDebug(debug: boolean) {
    ActionCable.logger.enabled = debug;
  }

  connected(): boolean {
    if (!this.consumer) {
      return false;
    }

    // unfortunately the correct typings for @rails/actioncable don't currently exist
    // instead of doing null checks on all these references just let the code bomb out
    // and sentry pick up the uncuaght exception...it's not expected for this to fail.
    return !this.consumer.connection.disconnected;
  }
}
