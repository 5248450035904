import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'ag1-button-base',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class Ag1ButtonBaseComponent implements OnInit {
  // pass in a routerLink if you'd like the button to act as a link
  // this is intended to be used with the routes that are defined in your *-routing.module.ts
  @Input()
  routerLink: any[] | string;

  // pass in queryParams if you'd like to add query parameters to the routerLink
  @Input()
  queryParams?: { [key: string]: any };

  @Input()
  variant: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success' =
    'primary';

  @Input()
  label = '';

  @Input()
  position: 'left' | 'right' = 'left';

  @Input()
  tooltip = '';

  @Input()
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';

  @Input()
  disabled: boolean;

  @Input()
  classOverrides: string = '';

  @Input() // used to show an active state when opening a ag1-menu
  isActive: boolean = false;

  @Input()
  formatWithSentenceCase: boolean = true;

  @Output()
  clickEvent = new EventEmitter<Event>();

  variantClasses: string = `ag1-button--${this.variant} `;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onClick(event: Event): void {
    // extra layer of protection
    if (this.disabled) {
      return;
    }

    if (this.routerLink) {
      // treat button as a link if it has a routerLink
      this.navigateToRouterLink();
      return; // unnecessary but explicit
    }

    this.clickEvent.emit(event);
  }

  navigateToRouterLink(): void {
    // since this is a SPA, we force that the link opens in the same tab
    // if you want to open the link in a new tab, please considder the implications
    const parts: Array<string | any> = Array.isArray(this.routerLink)
      ? this.routerLink
      : [this.routerLink];

    const navigationExtras: NavigationExtras = {};

    if (this.queryParams) {
      navigationExtras.queryParams = this.queryParams;
    }

    this.router.navigate(parts, navigationExtras);
  }
}
