import {
  Injectable,
  ComponentFactoryResolver,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {
  ConfirmModalComponent,
  ConfirmModalConfig,
} from '../../components/confirm-modal/confirm-modal.component';
import { ModalHostDirective } from '../../directives/modal-host.directive';
import {
  InputModalComponent,
  InputModalConfig,
} from '../../components/input-modal/input-modal.component';
import { Modal } from '../../interfaces/modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private host: ModalHostDirective;

  constructor() {}

  get containerRef(): ViewContainerRef {
    return this.host?.viewContainerRef;
  }

  setHost(host: ModalHostDirective) {
    this.host = host;
  }

  clearHost() {
    if (!this.host) {
      throw new Error('ModalService ModalHostDirective is not defined');
    }
    this.containerRef.clear();
  }

  showConfirm(config: ConfirmModalConfig): ConfirmModalComponent {
    return this.createModalInstance(ConfirmModalComponent, config);
  }

  showInput(config: InputModalConfig): InputModalComponent {
    return this.createModalInstance(InputModalComponent, config);
  }

  createModalInstance<T extends Modal>(type: Type<T>, config: {}): T {
    if (this.host) {
      // TODO: Can remove this clear host call when all modals are ported to modal base
      this.clearHost();

      const componentRef = this.containerRef.createComponent(type);
      const instance = <T>componentRef.instance;
      instance.configure(config);
      instance.show();
      return instance;
    } else {
      console.error('no modal host');
    }
    return null;
  }
}
