export function inflate(node, index = {}, path = []) {
  if (node && node.id && node.__typename) {
    const route = path.join(",");

    if (
      index[route] &&
      index[route][node.__typename] &&
      index[route][node.__typename][node.id]
    ) {
      return index[route][node.__typename][node.id];
    }

    if (!index[route]) {
      index[route] = {};
    }

    if (!index[route][node.__typename]) {
      index[route][node.__typename] = {};
    }

    index[route][node.__typename][node.id] = node;
  }

  const fieldNames = Object.keys(node);

  const result = Array.isArray(node) ? [] : {};

  for (const fieldName of fieldNames) {
    const value = node[fieldName];

    if (Array.isArray(value)) {
      result[fieldName] = value.map((childNode) => {
        if (!(childNode instanceof Object)) {
          return childNode;
        }

        return inflate(childNode, index, path.concat([fieldName]));
      });
    } else if (typeof value === "object" && value !== null) {
      result[fieldName] = inflate(value, index, path.concat([fieldName]));
    } else {
      result[fieldName] = value;
    }
  }

  return result;
}
