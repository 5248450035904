<div class="flex gap-4 flex-col">
  <div
    class="flex gap-2 items-center whitespace-nowrap flex-wrap justify-between"
  >
    <ag1-title label="500 Error"></ag1-title>
  </div>
  <div
    class="
      grid
      place-items-center
      gap-2
      h-[calc(100vh-13rem)]
      @container
      overflow-hidden
    "
  >
    <ng-container *ngTemplateOutlet="serverErrorDisplay"></ng-container>
  </div>
</div>

<ng-template #serverErrorDisplay>
  <div>
    <div
      class="
        grid grid-cols-1
        @lg:grid-cols-2
        gap-10
        p-5
        max-w-4xl
        items-center
        justify-items-center
        overflow-visible
        h-full
      "
    >
      <!-- left -->
      <div
        class="
          row-start-2
          @lg:row-start-1
          max-w-lg
          text-center
          flex flex-col
          items-center
          @lg:text-start
          @lg:items-start
        "
      >
        <h1 class="text-5xl font-extrabold text-steel-blue-600">
          Page Not Found
        </h1>
        <p class="text-xl text-gray-600 mt-5">
          Looks like an error has occoured. This error has been logged in our
          system. Feel free to contact us if you feel something is incorrect.
        </p>
        <p class="mt-5 font-semibold">Error code: 500</p>
        <div class="flex gap-3 items-center mt-5">
          <ag1-button
            class="text-white font-semibold"
            variant="primary"
            (clickEvent)="goBack()"
            >Go Back</ag1-button
          >
        </div>
      </div>

      <!-- right -->
      <div class="relative max-h-fit w-fit h-fit p-1 row-start-1">
        <p
          class="
            absolute
            left-3
            top-3
            text-9xl
            z-10
            text-yellow-500/40
            scale-150
          "
        >
          5
        </p>
        <img
          src="/assets/images/error_images/lemon-500-page.png"
          alt="500 Image"
          class="z-20 relative"
        />
        <p
          class="
            text-9xl
            absolute
            right-0
            bottom-0
            z-10
            text-yellow-500/40
            scale-150
          "
        >
          0
        </p>
      </div>
    </div>
  </div>
</ng-template>
