<div *ngIf="initialized" class="flex h-screen bg-slate-50">
  <ag-nav
    class="shrink-0"
    [isExistingUser]="isExistingUser"
    [myCompany]="myCompany"
  ></ag-nav>

  <section
    class="
      grow
      overflow-auto
      sm:portrait:!mt-0
      md:landscape:!mt-0
      !mt-16
      relative
    "
    [ngClass]="hasPadding ? 'p-5' : ''"
  >
    <ag-notification
      [(visible)]="showCertificateExpiryBanner"
      [type]="'is-warning'"
      [isMessage]="true"
    >
      <div notification-header>Attention</div>
      <div notification-body>
        You have company certificates that have expired, or are in danger of
        expiring:<br /><br />
        <ul>
          <ng-container *ngFor="let cert of myCompany?.certificates">
            <li *ngIf="expired(cert)">
              {{ cert.certificate.filename }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ag-notification>
    <router-outlet></router-outlet>
    <ag1-toast-notification></ag1-toast-notification>
  </section>
</div>

<ng-template agModalHost></ng-template>
