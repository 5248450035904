/**
 * Does not work on classes
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const clone = (value: Object) => {
  if (!value) {
    return value;
  }
  return JSON.parse(JSON.stringify(value));
};
