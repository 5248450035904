<button
  class="
    {{ classOverrides }}
    ag1-button--small
    ag1-button--{{ variant }}
    flex
    items-center
    justify-center
    gap-3
  "
  [ngClass]="[
    position !== 'right' ? 'flex-row' : 'flex-row-reverse',
    isActive ? 'ag1-button--active' : '',
    !label.length ? 'aspect-square' : ''
  ]"
  [ag1Tooltip]="tooltip"
  [tooltipPosition]="tooltipPosition"
  [tooltipVariant]="variant"
  [disabled]="disabled"
  (click)="onClick($event)"
>
  <ng-content></ng-content>
  <span *ngIf="label">
    <ng-container *ngIf="!formatWithSentenceCase">
      {{ label }}
    </ng-container>
    <ng-container *ngIf="formatWithSentenceCase">
      {{ label | sentenceCase }}
    </ng-container>
  </span>
</button>
