import { Component, OnInit } from '@angular/core';
import { UrlTrackerService } from '@app/core/services/url-tracker/url-tracker.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'ag-four-oh-four',
  templateUrl: './four-oh-four.component.html',
  styleUrls: ['./four-oh-four.component.scss'],
})
export class FourOhFourComponent implements OnInit {
  previousUrl: String;

  constructor(
    private router: Router,
    urlTrackerService: UrlTrackerService,
    private location: Location
  ) {
    this.previousUrl = urlTrackerService.previousUrl;
  }

  ngOnInit() {}

  goBack(): void {
    this.location.back();
  }

  goHome(): void {
    this.router.navigate(['account'], { replaceUrl: true });
  }
}
