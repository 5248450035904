<!-- This button triggers the overlay and is its origin -->
<div
  (click)="openMenu()"
  cdkOverlayOrigin="trigger"
  #trigger="cdkOverlayOrigin"
  [ngClass]="isOpen ? 'select-none outline-none rounded' : ''"
>
  <ng-container *ngTemplateOutlet="customTriggerRef"></ng-container>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen && !isModal"
  [cdkConnectedOverlayPositions]="preferedPosition"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="bg-transparent"
  (detach)="closeMenu()"
  (backdropClick)="closeMenu()"
>
  <div class="bg-white rounded-lg shadow-xl my-1">
    <ng-container *ngTemplateOutlet="customOverlayRef"></ng-container>
  </div>
</ng-template>
