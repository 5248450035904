import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PermissionGuard } from '../core/guards/permission/permission.guard';
import { LandingModule } from '../landing/landing.module';
import { CoreModule } from '../core/core.module';
import { NavComponent } from '../shared//nav/nav.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { AddtionalFieldsModalComponent } from './new-order-v2/addtional-fields-modal/addtional-fields-modal.component';
import { OpenAgriComponentsModule } from '@openagri/components';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { ConfirmActionModalComponent } from './view-order/confirm-action-modal/confirm-action-modal.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@environments/environment.prod';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { GraphQLModule } from '@app/graphql/graphql.module';
import { AccountRoutingModule } from './account-routing.module';
import { AdditionalFieldsMismatchModalComponent } from './new-order-v2/additional-fields-mismatch-modal/additional-fields-mismatch-modal.component';
import { CardService } from './dashboard/card.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AccountComponent,
    NavComponent,
    AddtionalFieldsModalComponent,
    AdditionalFieldsMismatchModalComponent,
    FourOhFourComponent,
    ServerErrorComponent,
    ConfirmActionModalComponent,
  ],
  imports: [
    GraphQLModule,
    CoreModule,
    AccountRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    LandingModule,
    DropzoneModule,
    OpenAgriComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gcp.maps.apiKey,
    }),
    AgmJsMarkerClustererModule,
  ],
  exports: [RouterModule],
  providers: [PermissionGuard, CardService, DatePipe],
})
export class AccountModule {}
