import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlTrackerService {
  private prevUrl: String;

  get previousUrl(): String {
    return this.prevUrl;
  }

  constructor(private router: Router) {}

  trackPreviousUrl() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.prevUrl = e.url;
      }
    });
  }
}
