import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { PermissionService } from '@app/core/services/permission.service';
import { PermissionEnum } from '@app/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class LocalizeJsService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private permissionService: PermissionService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  async initLocalizeJS() {
    const hasPermission = await this.permissionService.allow(
      PermissionEnum.GeneralEnableLocalize
    );

    if (hasPermission) {
      this.injectScript();
    }
  }

  private injectScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://global.localizecdn.com/localize.js';
    script.onload = () => this.initializeLocalize();
    this.renderer.appendChild(document.head, script);
  }

  private initializeLocalize() {
    const initScript = this.renderer.createElement('script');
    const scriptContent = this.renderer.createText(`
      !(function (a) {
        a.Localize = a.Localize || {};
        var e = ["translate", "untranslate", "phrase", "initialize", "translatePage", "setLanguage", "getLanguage", "getSourceLanguage", "detectLanguage", "getAvailableLanguages", "setWidgetLanguages", "hideLanguagesInWidget", "untranslatePage", "bootstrap", "prefetch", "on", "off", "hideWidget", "showWidget"];
        for (var t = 0; t < e.length; t++) a.Localize[e[t]] = a.Localize[e[t]] || function () {};
        Localize.initialize({
          key: "rwqDJIskpDCLc",
          rememberLanguage: true
        });
      })(window);
    `);
    this.renderer.appendChild(initScript, scriptContent);
    this.renderer.appendChild(document.body, initScript);
  }
}
