import { Component, OnInit } from '@angular/core';
import { NavService } from '@app/core/services/nav.service';
import { Toast, ToastService } from '@app/core/services/toast/toast.service';

@Component({
  selector: 'ag1-toast-notification',
  templateUrl: './ag1-toast-notification.component.html',
})
export default class Ag1ToastNotificationComponent implements OnInit {
  toasts: Toast[] = [];

  constructor(
    private toastService: ToastService,
    private navService: NavService
  ) {}

  get expandedSidebar(): boolean {
    return this.navService.expandedSidebar;
  }

  ngOnInit(): void {
    this.toastService.getToast().subscribe((toasts) => {
      this.toasts = toasts;
    });
  }

  closeToast(index: number): void {
    this.toastService.closeToast(index);
  }

  toastStyle(type): string {
    switch (type) {
      case 'success':
        return 'border-l-green-500 bg-green-500/20';
      case 'warning':
        return 'border-l-yellow-400 bg-yellow-400/20';
      case 'error':
        return 'border-l-red-500 bg-red-500/20';
      default:
        return 'border-l-french-blue-500 bg-french-blue-500/20';
    }
  }
}
