import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './account.component';
import { AuthenticationGuard } from '@app/core/guards/authentication/authentication.guard';
import { PermissionGuard } from '@app/core/guards/permission/permission.guard';
import { Version2Guard } from '@app/core/guards/version-2/version2-guard.guard';
import { ExistingUserGuard } from '@app/core/guards/existing-user/existing-user.guard';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { PermissionEnum } from '@app/generated/graphql';

export const routes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      // used for demo purposes
      {
        path: 'feature-preview',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesDemo,
        },
        loadChildren: () =>
          import('./feature-preview/feature-preview.module').then(
            (m) => m.FeaturePreviewModule
          ),
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        canActivate: [PermissionGuard, Version2Guard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesDashboard,
        },
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'settings',
        pathMatch: 'full',
        canActivate: [PermissionGuard],
        data: {
          resourceId: PermissionEnum.PagesSettings,
        },
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'company',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesCompany,
        },
        loadChildren: () =>
          import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'order/:id',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesViewOrder,
        },
        loadChildren: () =>
          import('./view-order/view-order.module').then(
            (m) => m.ViewOrderModule
          ),
      },
      {
        path: 'new-order',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesNewOrder,
        },
        loadChildren: () =>
          import('./new-order-router/new-order-router.module').then(
            (m) => m.NewOrderRouterModule
          ),
      },
      {
        path: 'orders',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesOrders,
        },
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'partners',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesPartners,
        },
        loadChildren: () =>
          import('./trade-partners/trade-partners.module').then(
            (m) => m.TradePartnersModule
          ),
      },
      {
        path: 'estimations',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesEstimations,
        },
        loadChildren: () =>
          import('./estimation/estimation.module').then(
            (m) => m.EstimationModule
          ),
      },
      {
        path: 'planning',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesPlanning,
        },
        loadChildren: () =>
          import('./planning/planning.module').then((m) => m.PlanningModule),
      },
      {
        path: 'pack-instructions',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesPackInstructions,
        },
        loadChildren: () =>
          import('./pack-instructions/pack-instructions.module').then(
            (m) => m.PackInstructionsModule
          ),
      },
      {
        path: 'load-out-instructions',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesLoadOutInstructions,
        },
        loadChildren: () =>
          import('./load-out-instructions/load-out-instructions.module').then(
            (m) => m.LoadOutInstructionsModule
          ),
      },
      {
        path: 'shipments',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesShipments,
        },
        loadChildren: () =>
          import('./shipments/shipments.module').then((m) => m.ShipmentsModule),
      },
      {
        path: 'air-freight-shipments',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesAirFreightShipments,
        },
        loadChildren: () =>
          import('./air-freight-shipments/air-freight-shipments.module').then(
            (m) => m.AirFreightShipmentsModule
          ),
      },
      {
        path: 'commercial-invoices',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesCommercialInvoices,
        },
        loadChildren: () =>
          import('./commercial-invoices/commercial-invoices.module').then(
            (m) => m.CommercialInvoicesModule
          ),
      },
      {
        path: 'pallets',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          isLineItemBased: false,
          resourceId: PermissionEnum.PagesPallets,
        },
        loadChildren: () =>
          import('./pallets/pallets.module').then((m) => m.PalletsModule),
      },
      {
        path: 'cash-flow',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          isLineItemBased: true,
          resourceId: PermissionEnum.PagesCashFlow,
        },
        loadChildren: () =>
          import('./cash-flow/cash-flow.module').then((m) => m.CashFlowModule),
      },
      {
        path: 'finance',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesCashFlow,
        },
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: 'commercial-terms',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesCommercialTerms,
        },
        loadChildren: () =>
          import('./commercial-terms/commercial-terms.module').then(
            (m) => m.CommercialTermsModule
          ),
      },
      {
        path: 'financial-invoices',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesCustomerInvoices,
        },
        loadChildren: () =>
          import('./financial-invoices/financial-invoices.module').then(
            (m) => m.FinancialInvoicesModule
          ),
      },
      {
        path: 'vendor-invoices',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesVendorInvoices,
        },
        loadChildren: () =>
          import('./vendor-invoices/vendor-invoices.module').then(
            (m) => m.VendorInvoicesModule
          ),
      },
      {
        path: 'default-costs',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesDefaultCosts,
        },
        loadChildren: () =>
          import('./default-costs/default-costs.module').then(
            (m) => m.DefaultCostsModule
          ),
      },
      {
        path: 'finance-rules',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesFinanceRuleManagement,
        },
        loadChildren: () =>
          import('./finance-rules/finance-rules-routing.module').then(
            (m) => m.FinanceRuleRoutingModule
          ),
      },
      {
        path: 'payments',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesPayments,
        },
        loadChildren: () =>
          import('./payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'analysis-downloads',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesAnalysisDownloads,
        },
        loadChildren: () =>
          import('./analysis-downloads/analysis-downloads.module').then(
            (m) => m.AnalysisDownloadsModule
          ),
      },
      {
        path: 'data-exports',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesDataExports,
        },
        loadChildren: () =>
          import('./data-exports/data-exports.module').then(
            (m) => m.DataExportsModule
          ),
      },
      {
        path: 'insights',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesInsights,
        },
        loadChildren: () =>
          import('./insights/index/index.module').then(
            (m) => m.InsightsIndexModule
          ),
      },
      {
        path: 'insights/report-group/:id',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesInsights,
        },
        loadChildren: () =>
          import('./insights/view-report-group/view.module').then(
            (m) => m.InsightsReportGroupViewModule
          ),
      },
      {
        path: 'insights/:id',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesInsights,
        },
        loadChildren: () =>
          import('./insights/view-metabase/view.module').then(
            (m) => m.InsightsMetabaseViewModule
          ),
      },
      {
        path: 'stock',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesStock,
        },
        loadChildren: () =>
          import('./stock/stock.module').then((m) => m.StockModule),
      },
      {
        path: 'masterfiles',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          resourceId: PermissionEnum.PagesMasterfiles,
        },
        loadChildren: () =>
          import('./masterfiles/masterfiles.module').then(
            (m) => m.MasterfilesModule
          ),
      },
      {
        path: '404',
        component: FourOhFourComponent,
        data: {},
      },
      {
        path: '500',
        component: ServerErrorComponent,
        data: {},
      },
      {
        path: '**',
        redirectTo: '/account/404',
        data: {},
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
