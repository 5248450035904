import { CompanyCertificate } from '@app/generated/graphql';

export abstract class CertificateHelper {
  static certificateName(cert: CompanyCertificate) {
    if (cert.certificateType) {
      return cert.certificateType.name;
    }
    return null;
  }

  static daysTillExpiry(cert: CompanyCertificate) {
    const diff = new Date(cert.expiryDate).getTime() - Date.now();
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  static inDangerOfExpiry(cert: CompanyCertificate) {
    return !this.isExpired(cert) && this.daysTillExpiry(cert) <= 30;
  }

  static isExpired(cert: CompanyCertificate) {
    return this.daysTillExpiry(cert) <= 0;
  }

  static isUnknownType(cert: CompanyCertificate): boolean {
    if (cert.certificateType.name) {
      return cert.certificateType.name.toLowerCase() === 'other';
    }
    return true;
  }
}
