import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'ag-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor() {
    // if (this.updates.isEnabled) {
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       const url = (<NavigationEnd>event).url;
    //       if (url !== '/login' && environment.production) {
    //         // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    //         const appIsStable = this.appRef.isStable.pipe(first(isStable => isStable === true));
    //         const every5minutes = interval(5 * 60 * 1000);
    //         const every5minutesAppIsStable = concat(appIsStable, every5minutes);
    //         every5minutesAppIsStable.subscribe(() => updates.checkForUpdate());
    //         this.updates.available.subscribe(_ => {
    //           const modalInstance = this.modalService.showConfirm({
    //             title: 'New version available!',
    //             content: 'There is a newer version of AgrigateOne available. ' +
    //               'Please click the update button or reload your page to download the new version',
    //             confirmBtnText: 'Update application',
    //             confirmBtnClass: 'is-primary',
    //             canCancel: false,
    //             onConfirm: () => {
    //               modalInstance.setLoading(true);
    //               this.updates.activateUpdate().then(() => document.location.reload());
    //             }
    //           });
    //         });
    //       }
    //     }
    //   });
    // }
  }

  ngOnInit(): void {
    Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.sentry.environment,
    });
  }
}
