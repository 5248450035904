import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '@app/generated/graphql';
import { deepEquals } from '@app/core/util/deep.equals';
import { clone } from '@app/core/util/clone';

@Injectable({
  providedIn: 'root',
})
export class NewOrderStore {
  private NEW_ORDER = 'new_order';
  private order: Order;
  private defaultOrder: Order;
  private unsaved: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    if (localStorage.getItem(this.NEW_ORDER)) {
      this.order = JSON.parse(localStorage.getItem(this.NEW_ORDER));
      this.unsaved.next(true);
    }
  }

  get(): Order {
    return this.order;
  }

  set(order: Order): void {
    if (!deepEquals(this.defaultOrder, order)) {
      this.order = order;
      localStorage.setItem(this.NEW_ORDER, JSON.stringify(this.order));
      this.unsaved.next(true);
    }
  }

  setDefault(defaultOrder: Order): void {
    this.defaultOrder = clone(defaultOrder);
    localStorage.removeItem(this.NEW_ORDER);
  }

  clear(defaultOrder: Order): void {
    this.order = clone(defaultOrder);
    localStorage.removeItem(this.NEW_ORDER);
    this.unsaved.next(false);
  }

  get isUnsaved(): Observable<boolean> {
    return this.unsaved.asObservable();
  }
}
