import { User } from '@app/generated/graphql';

export abstract class UserHelper {
  static getFullName(user: User) {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return null;
  }

  static isAgrigateUser(user: User) {
    return user.email.includes('@agrigate');
  }

  static isNewUser(user: User) {
    return user.state === 'invited';
  }

  static isExistingUser(user: User) {
    return user.state === 'active';
  }
}
