import { trigger, animate, transition, style } from '@angular/animations';

const hiddenProps = {
  opacity: 0.1,
  transform: 'scaleY(0) scaleX(0.2)',
  maxHeight: 0,
};

const visibleProps = {
  opacity: 1,
  transform: 'scaleY(1) scaleX(1)',
  maxHeight: '100vh',
};

export const notificationScaleInAnimation = trigger(
  'notificationScaleInAnimation',
  [
    transition(':enter', [
      style(hiddenProps),
      animate(
        '0.23s cubic-bezier(0.645, 0.045, 0.355, 1)',
        style(visibleProps)
      ),
    ]),

    transition(':leave', [
      style(visibleProps),
      animate('0.23s cubic-bezier(0.645, 0.045, 0.355, 1)', style(hiddenProps)),
    ]),
  ]
);

export const notificationAnimations = [notificationScaleInAnimation];
